<template>
  <common>
    <template v-slot:headers>
      <div @click="back" class="back-img" />
      <div class="title">{{ myPara.title }}</div>
      <div
        @click="handleOk"
        v-if="para.handleOk"
        style="color: #ffffff; font-weight: 400; font-size: 1.54rem; margin-right: 1.79rem; margin-left: auto"
      >
        {{ para.okText }}
      </div>
      <div
        @click="show = true"
        v-else-if="para.addtask"
        class="el-icon-circle-plus-outline add-task-ico"
        style="margin-right: 1.79rem; margin-left: auto"
      />
      <div v-else style="margin-right: 1.79rem; margin-left: auto" />
    </template>
    <component
      v-on="$listeners"
      :value="myPara.value"
      :id="this.para.id"
      @currSelect="handleCurrSelect"
      @handleSave="handleSave"
      :is="myPara.id"
    ></component>
    <transition name="my-sheet">
      <div class="cover sheet-cover" style="z-index: 10000" v-show="show" @click.self="closeAddtask">
        <div class="main-frame">
          <div class="flex-w head-nav">
            <span class="nav" @click.stop="closeAddtask">取消</span>
            <span class="title">新建子任务</span>
            <span class="nav" @click="addSubTask">完成</span>
          </div>
          <div class="task-form">
            <input type="text" placeholder="请输入任务标题" v-model="title" />
          </div>
        </div>
      </div>
    </transition>
  </common>
</template>
<script>
import { postTaskSubNew, postDailyBoxSubNew } from '@/service/help';
import datePick from '@/components/mobile/formContainer/time.vue';
import list from '@/components/mobile/formContainer/list.vue';
import inputBox from '@/components/mobile/formContainer/inputBox.vue'
import newTask from '@/components/mobile/formContainer/newTask.vue';
export default {
  props: ['para'],
  data() {
    return {
      fishData: null,
      show: false,
      title: '',
    };
  },
  components: {
    datePick,
    list,
    newTask,
    inputBox
  },
  created() {},
  methods: {
    closeAddtask() {
      this.show = false;
      this.title = '';
    },
    addSubTask() {
      if (!this.title) {
        this.$toast.fail('标题不能为空');
      } else {
        if (this.para.id == 'taskLis') {
          postTaskSubNew({
            dadtask_id: this.myPara.value.toString(),
            title: this.title,
            charge_id: '',
            level: '',
            join_id: '',
            type: '',
          }).then(res => {
            if (res.data.success) {
              this.$toast.success('新增成功');
              this.$parent.isSelecting = false;
              this.$nextTick(() => {
                this.$parent.$set(this.$parent.summary, 'SubSum', parseInt(this.$parent.summary['SubSum']) + 1);
                this.$parent.handleNext('SubSum');
              });
            } else {
              this.$toast.fail(res.data.msg);
            }
          });
        } else if (this.para.id == 'dailyLis') {
          postDailyBoxSubNew({
            clean_dad: this.myPara.value.toString(),
            title: this.title,
            clean_etime: '',
          }).then(res => {
            if (res.data.success) {
              this.$toast.success('新增成功');
              this.$parent.isSelecting = false;
              this.$nextTick(() => {
                this.$parent.$set(this.$parent.summary, 'sub_sum', parseInt(this.$parent.summary['sub_sum']) + 1);
                this.$parent.handleNext('sub_sum');
              });
            } else {
              this.$toast.fail(res.data.msg);
            }
          });
        }
      }
      this.closeAddtask();
    },
    handleOk() {
      if (this.para.type == 'addSubtask' || this.para.type == 'addtask') {
        this.$emit(this.para.type, this.fishData);
      } else {
        this.handleChange();
      }
    },
    handleCurrSelect(data) {
      //
      console.log(data);
      this.$set(this, 'fishData', data);
      if (!this.para.chill) {
        //没有设置chill的直接返回
        this.handleChange(); //直接变
      }
    },
    handleSave(data){
      this.$set(this, 'fishData', data);
      this.handleChange(); //直接变
    },
    back() {
      //不做任何改变
      this.$emit('back');
    },
    handleChange() {
      //已经做了选择
      this.$emit('handleChange', { data: this.fishData, type: this.myPara.type });
    },
  },
  computed: {
    myPara() {
      let specialMap = {
        statusLis: list,
        levelLis: list,
        typeLis: list,
        memberLis: list,
        taskLis: list,
        dailyLis: list,
        sortInput: inputBox
      };
      return {
        id: specialMap[this.para.id] ? specialMap[this.para.id] : this.para.id || '',
        title: this.para.title || '',
        type: this.para.type || '',
        value: this.para.value || '',
      };
      // return {
      //   id: 'statusLis',
      //   title: '选择任务状态',
      //   type: 'status',
      //   value:1
      // };
    },
  },
};
</script>

<style lang="less">
.form {
  background-color: #f3f7f9;
  width: 32rem;
  height: 100%;
  /* height: 61.78rem; */
  background: rgb(243, 247, 249);
  border-radius: 0.85rem;
}
.add-task-ico {
  color: #fff;
  font-size: 2.25rem;
}
</style>
