<template>
  <div class="form">
    <div class="date-pick">
      <div class="flex-w date-header">
        <i class="el-icon-caret-left direction" @click="preMonth" />
        <div class="selected-date">{{ curYear + '年' + format(curMonth) + '月' }}</div>
        <i class="el-icon-caret-right direction" @click="nextMonth" />
      </div>
      <div class="calendar-box-container">
        <div class="flex-w calendar-box">
          <div class="weekday-label" style="color: #13bfff">周日</div>
          <div class="weekday-label">周一</div>
          <div class="weekday-label">周二</div>
          <div class="weekday-label">周三</div>
          <div class="weekday-label">周四</div>
          <div class="weekday-label">周五</div>
          <div class="weekday-label" style="color: #13bfff">周六</div>
        </div>
        <div v-for="week in dateList" class="flex-w week-box">
          <div @click="handleSelectDate(day)" class="day-label" :devent="day.event || ''" v-for="day in week">
            <template v-if="today != day.value || selectedDate == day.value">
              <div :class="['label', selectedDate == day.value ? 'active' : '', today == day.value ? 'today' : '']">
                {{ day.date }}
              </div>
            </template>
            <div v-else :class="['label', 'today']">今</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['value'],
  data() {
    return {
      weekArr: ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
      monthList: [
        {
          '01': '一月',
        },
        {
          '02': '二月',
        },
        {
          '03': '三月',
        },
        {
          '04': '四月',
        },
        {
          '05': '五月',
        },
        {
          '06': '六月',
        },
        {
          '07': '七月',
        },
        {
          '08': '八月',
        },
        {
          '09': '九月',
        },
        {
          10: '十月',
        },
        {
          11: '十一月',
        },
        {
          12: '十二月',
        },
      ],
      daysCountArr: [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
      dateTimes: [
        {
          day: '1-1',
          target: '元旦节',
        },
        {
          day: '2-2',
          target: '世界湿地节',
        },
        {
          day: '2-14',
          target: '情人节',
        },
        {
          day: '3-8',
          target: '妇女节',
        },
        {
          day: '3-12',
          target: '植树节',
        },
        {
          day: '3-14',
          target: '白色情人节',
        },
        {
          day: '4-1',
          target: '愚人节',
        },
        {
          day: '4-7',
          target: '世界卫生日',
        },
        {
          day: '5-1',
          target: '劳动节',
        },
        {
          day: '5-4',
          target: '青年节',
        },
        {
          day: '5-8',
          target: '红十字日',
        },
        {
          day: '5-12',
          target: '护士节',
        },
        {
          day: '6-1',
          target: '儿童节',
        },
        {
          day: '6-12',
          target: '世界禁毒日',
        },
        {
          day: '7-7',
          target: '抗日纪念日',
        },
        {
          day: '8-1',
          target: '建军节',
        },
        {
          day: '9-10',
          target: '教师节',
        },
        {
          day: '10-1',
          target: '国庆节',
        },
        {
          day: '10-24',
          target: '程序员节',
        },
        {
          day: '10-31',
          target: '万圣节',
        },
        {
          day: '11-18',
          target: '记者节',
        },
        {
          day: '12-15',
          target: '圣诞节',
        },
      ],
      dateList: [],
      curYear: '', //当前年份
      curMonth: '', //当前月份
      selectedDate: '', //选中的几月几号
      selectedWeek: '', //选中的是星期几
      today: '',
      nowDate: '', //节日用途
      nowEvent: '', //当前节日
    };
  },
  mounted() {
    let today = new Date();
    this.curYear = today.getFullYear();
    this.curMonth = today.getMonth() + 1;
    let date = today.getDate();
    let i = today.getDay();
    // this.selectedDate = `${this.curYear}-${this.format(this.curMonth)}-${this.format(date)}`;
    this.initSelectedDate(this.value);
    console.log(new Date(this.value));
    this.today = `${this.curYear}-${this.format(this.curMonth)}-${this.format(date)}`;
    this.nowDate = `${this.curMonth}-${date}`;
    this.selectedWeek = this.weekArr[i];
    this.getDateList(this.curYear, this.curMonth - 1, this.nowDate);
  },
  methods: {
    initSelectedDate(date) {
      //初始化当前选择的日期
      if (!date) {
        this.selectedDate = '';
      } else {
        let today = new Date(date);
        let curYear = today.getFullYear();
        let curMonth = today.getMonth() + 1;
        let returnDate = today.getDate();
        this.selectedDate = `${curYear}-${this.format(curMonth)}-${this.format(returnDate)}`;
      }
    },
    format(num) {
      if (num < 10) {
        return '0' + num;
      } else {
        return num;
      }
    },
    handleSelectDate(day) {
      if (this.selectedDate != day.value) {
        this.selectedDate = day.value;
      } else {
        this.selectedDate = '';
      }
      this.$emit('currSelect', day);
    },
    getDateList(year, month, nowDate) {
      if (year % 4 === 0 && year % 100 !== 0) {
        this.$set(this.daysCountArr, [1], 29);
      }
      let dateList = [];
      dateList[0] = [];
      let weekIndex = 0;
      const tDateTime = this.dateTimes;
      for (let i = 0; i < this.daysCountArr[month]; i++) {
        let week = new Date(Date.UTC(year, month, i + 1)).getDay();
        let m = month + 1 < 10 ? '0' + (month + 1) : month + 1;
        let d = i + 1 < 10 ? '0' + (i + 1) : i + 1;
        dateList[weekIndex].push({
          value: `${year}-${m}-${d}`,
          dateTime: `${month + 1}-${i + 1}`,
          date: i + 1 < 10 ? '0' + (i + 1) : i + 1,
          week,
          //仅为展示测试
        });
        for (let k in this.dateTimes) {
          // console.log(dateList[weekIndex])
          for (let j in dateList[weekIndex]) {
            // 判断当前月份日期是否有和节日列表中的日期相等
            if (dateList[weekIndex][j].dateTime === tDateTime[k].day) {
              dateList[weekIndex][j].event = tDateTime[k].target;
            }
            // 判断月份
            if (dateList[weekIndex][j].value === tDateTime[k].day) {
              dateList[weekIndex][j].event = tDateTime[k].target;
            }
            if (nowDate === tDateTime[k].day) {
              this.nowEvent = tDateTime[k].target;
            }
          }
        }
        if (week === 6) {
          weekIndex++;
          dateList[weekIndex] = [];
        }
      }
      this.$set(this, 'dateList', dateList);
    },
    preMonth() {
      this.curYear = this.curMonth - 1 ? this.curYear : this.curYear - 1;
      this.curMonth = this.curMonth - 1 ? this.curMonth - 1 : 12;
      this.getDateList(this.curYear, this.curMonth - 1, this.nowDate);
    },
    nextMonth() {
      this.curYear = this.curMonth + 1 === 13 ? this.curYear + 1 : this.curYear;
      this.curMonth = this.curMonth + 1 === 13 ? 1 : this.curMonth + 1;
      this.getDateList(this.curYear, this.curMonth - 1, this.nowDate);
    },
  },
};
</script>

<style lang="less" scope>
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.date-pick {
  background-color: #fff;
}
.date-header {
  height: 6.39rem;
  .selected-date {
    font-size: 1.54rem;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 2.13rem;
    color: #222b45;
  }
  .direction {
    font-size: 2.35rem;
    color: #eeeeee;
  }
}
.calendar-box-container {
  border-radius: 0.85rem;
  padding-bottom: 1.11rem;
  .label-box {
    font-size: 1.19rem;
    font-family: PingFang SC;
    font-weight: 400;
    width: calc(100% / 7);
    .flex-center;
  }
  .calendar-box {
    width: 100%;
    .weekday-label {
      .label-box;
      color: #8992a3;
    }
  }
  .week-box {
    justify-content: end;
    .day-label {
      position: relative;
      &::before {
        position: absolute;
        content: attr(devent);
        color: #8992a3;
        bottom: -0.5rem;
        white-space: nowrap;
      }
      .label-box;
      .label {
        width: 3.41rem;
        height: 3.41rem;
        border-radius: 50%;
        // margin-top:1.02rem;
        margin-top: 1rem;
        font-weight: bold;
        color: #222b45;
        .flex-center;
      }
      .today {
        color: #13bfff;
        background: #edfbff;
      }
      .active {
        color: #fff;
        background: #13bfff;
      }
    }
    &:last-child {
      justify-content: start;
    }
  }
}
</style>
