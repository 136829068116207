<template>
  <!--该组件负责渲染新建任务的表单,可以在此进行接口渲染-->
  <div class="from">
    <div class="detail-box title-box">
      <textarea class="task-title" placeholder="请输入任务标题" @input="handleSelect" v-model="title"></textarea>
    </div>
    <div class="flex-w detail-box">
      <div class="item-title">任务类型</div>
      <div>
        <span>{{ type }}</span>
        <i @click="handleNext('type')" class="el-icon-arrow-right"></i>
      </div>
    </div>
    <div class="flex-w detail-box">
      <div class="item-title">任务紧急度</div>
      <div>
        <template v-if="level != '' && level >= 0">
          <img style="width: 1.17rem; height: 1.17rem" :src="tasklevel[level].icon" />
          <span>{{ tasklevel[level].value }}</span>
        </template>
        <i @click="handleNext('level')" class="el-icon-arrow-right"></i>
      </div>
    </div>
    <div class="flex-w detail-box">
      <div class="item-title">负责人</div>
      <div class="flex-w">
        <template v-if="charge_name">
          <div class="head-draw">{{ lastName(charge_name) }}</div>
          <span>{{ charge_name }}</span>
        </template>
        <i class="el-icon-arrow-right" @click="handleNext('charge_name')"></i>
      </div>
    </div>
    <div class="flex-w detail-box">
      <div class="item-title">参与人</div>
      <div class="full flex-w join-one-box" v-if="typeof join_id == 'object'">
        <div class="head-draw join_head" v-for="item in join_id">{{ lastName(item.name) }}</div>
      </div>
      <i @click="handleNext('join_id')" class="el-icon-arrow-right"></i>
    </div>
  </div>
</template>

<script>
import { tasklevel } from '@/common/js';
export default {
  data() {
    return {
      tasklevel,
      title: '',
      type: '',
      level: '',
      charge_name: '',
      charge: {},
      join_id: [],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      let value = this.$attrs.value;
      console.log(value);
      Object.keys(value).forEach(k => {
        if (k == 'charge_name') {
          this.charge = value[k];
          this.charge_name = value[k].name;
        } else {
          this.$set(this, k, value[k]);
        }
      });
      this.handleSelect();
    },
    handleSelect() {
      let data = {
        title: this.title,
        type: this.type,
        level: this.level,
        charge_name: this.charge_name,
        charge_id: this.charge.id,
        join_id: this.join_id,
      };
      console.log(data);
      this.$emit('currSelect', data);
    },
    handleNext(type) {
      let formTarget = {
        charge_name: { type, title: '负责人', id: 'memberLis', value: this[type] },
        level: { type, title: '选择任务紧急度', id: 'levelLis', value: this[type] },
        type: { type, title: '选择任务紧急度', id: 'typeLis', value: this[type] },
        join_id: {
          type,
          title: '参与人',
          id: 'memberLis',
          value: this[type],
          chill: true,
          handleOk: true,
          okText: '确定',
        },
      };
      let data = formTarget[type] || null;
      this.$emit('handleNext', data);
    },
  },
};
</script>

<style lang="less" scope>
.detail-box {
  height: 4.69rem;
  width: 100%;
  background-color: #fff;
  border-radius: 0.85rem;
  padding: 0 1.37rem;
  margin-bottom: 0.68rem;
  font-size: 1.45rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #334681;
  .between;
  .item-title {
    flex: 0 0 35%;
    overflow: hidden;
  }
}
.between {
  justify-content: space-between !important;
}
.title-box {
  width: 32rem;
  height: 7.85rem;
  padding: 1.02rem;
}
.task-title {
  width: 100%;
  height: 100%;
  resize: none;
}
.join-one-box {
  overflow-x: scroll;
  justify-content: flex-end !important;
  .join_head {
    width: 2.05rem;
    height: 2.05rem;
    line-height: 2.05rem;
    font-size: 0.68rem;
    margin-right: 0.85rem;
    flex: 0 0 auto;
  }
}
</style>
