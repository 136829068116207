<template>
  <div class="flex-h form">
    <div class="flex-h search" v-if="searchVisable">
      <input class="search-input" v-model="keyword" placeholder="搜索" />
    </div>
    <ul :style="{ paddingTop: searchVisable ? 0 : '1.02rem' }">
      <template v-if="id == 'statusLis'">
        <li v-for="(item, index) in xIco" :key="index" @click="handleSelect(index)">
          <div :style="{ fontSize: '1.19rem', color: item.color }" class="flex-w left-flex-box">
            <img
              style="margin-right: 0.17rem; width: 1.71rem; height: 1.71rem; margin-right: 1.37rem"
              :src="item.ico"
              alt
            />
            <span>{{ item.str }}</span>
          </div>
        </li>
      </template>
      <template v-else-if="id == 'levelLis'">
        <li v-for="(item, index) in tasklevel" :key="index" @click="handleSelect(index)">
          <div class="flex-w left-flex-box level-item">
            <img style="width: 1.71rem; height: 1.71rem" :src="item.icon" />
            <span>{{ item.value }}</span>
          </div>
        </li>
      </template>
      <template v-else-if="id == 'typeLis'">
        <li v-for="(item, index) in taskTypeList" :key="index" @click="handleSelect(item)">
          <div class="flex-w left-flex-box">{{ item }}</div>
        </li>
      </template>
      <template v-else-if="id == 'memberLis'">
        <li
          v-for="(item, index) in memberSearch"
          :key="index"
          @click="
            handleSelect(item, () => {
              item.select = !item.select;
            })
          "
        >
          <div class="flex-w left-flex-box member-item">
            <img :src="item.avatar" class="avatar" />
            <span>{{ item.name }}</span>
            <template v-if="mutiSelect">
              <div v-if="item.select" class="select selected"></div>
              <div v-else class="select"></div>
            </template>
          </div>
        </li>
      </template>
      <template v-else-if="id == 'taskLis'">
        <li class="task-li" v-for="item in taskLis" :key="item.id" @click="handleSelect(item)">
          <div class="flex-w box1">
            <div
              :style="{ backgroundColor: xIco[item.status].bgColor, color: xIco[item.status].color }"
              class="flex-w status-ico"
            >
              <img :src="xIco[item.status].ico" alt />
              <span>{{ xIco[item.status].str }}</span>
            </div>
            <img class="portrait" :src="item.avatar" :alt="lastName(item.name)" v-if="item.name !== ''" />
          </div>
          <div class="title-show">{{ item.title }}</div>
        </li>
      </template>
      <template v-else-if="id == 'dailyLis'">
        <li class="task-li" v-for="item in dailyLis" :key="item.id" @click="handleSelect(item)">
          <div class="flex-w box1">
            <div
              :style="{ backgroundColor: xIco[item.clean_status].bgColor, color: xIco[item.clean_status].color }"
              class="flex-w status-ico"
            >
              <img :src="xIco[item.clean_status].ico" alt />
              <span>{{ xIco[item.clean_status].str }}</span>
            </div>
          </div>
          <div class="title-show">{{ item.title }}</div>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import { getTaskType, postTaskUser, postTaskSubLis, postDailyBoxSubLis } from '@/service/help';
import { xIco, tasklevel } from '@/common/js';
export default {
  props: ['id'],
  data() {
    return {
      searchVisable: false, //搜索框是否显示
      keyword: '', //搜索关键字
      xIco,
      tasklevel,
      taskTypeList: [],
      memberLis: [],
      mutiSelect: false, //是否多选
      taskLis: [],
      dailyLis: [],
    };
  },
  methods: {
    handleSelect(v, callback) {
      if (this.mutiSelect) {
        callback();
        let data = this.memberLis.filter(v1 => v1.select);
        this.$emit('currSelect', data);
        return;
      }
      this.$emit('currSelect', v);
    },
  },
  mounted() {
    console.log(this.id);
    if (this.id == 'typeLis') {
      getTaskType().then(res => {
        this.taskTypeList = res.data.data;
      });
    } else if (this.id == 'memberLis') {
      this.searchVisable = true;
      if (typeof this.$attrs.value == 'object') this.mutiSelect = true;
      postTaskUser({ id: '' }).then(res => {
        console.log(res);
        let data = res.data.data;
        if (this.mutiSelect) {
          let ids = this.$attrs.value.map(v => v.id) || [];
          data = data.map(v => {
            v.select = false;
            if (ids.includes(v.id)) {
              v.select = true;
            }
            return v;
          });
          //一开始先发送变化事件
          this.$emit(
            'currSelect',
            data.filter(v1 => v1.select),
          );
        }
        this.memberLis = data;
      });
    } else if (this.id == 'taskLis') {
      postTaskSubLis({ dadtask_id: this.$attrs.value.toString() }).then(res => {
        let data = res.data.data;
        if (data.length > 0) {
          this.taskLis = data;
        }
      });
    } else if (this.id == 'dailyLis') {
      postDailyBoxSubLis({ clean_dad: this.$attrs.value.toString() }).then(res => {
        let data = res.data.data;
        if (data.length > 0) {
          this.dailyLis = data;
        }
      });
    }
  },
  computed: {
    memberSearch() {
      return this.memberLis.filter(v => {
        return v.name.indexOf(this.keyword) >= 0;
      });
    },
  },
};
</script>

<style scoped lang="less">
.search {
  margin-bottom: 1.02rem;
  width: 32rem;
  height: 3.75rem;
  background: #fff;
  border-radius: 1rem 1rem 0rem 0rem;
  .search-input {
    width: 29.27rem;
    height: 2.56rem;
    background-color: #f7f8fa;
    padding: 0.8rem;
    border-radius: 0.17rem;
    &::placeholder {
      text-align: center;
      text-indent: 2rem;
    }
    &:placeholder-shown {
      background-image: url('~@/assets/image/icon-progress/icon_search@2x.png');
      background-repeat: no-repeat;
      background-position: 12.54rem 0.68rem;
      background-size: 1.2rem 1.19rem;
    }
  }
}
ul {
  padding: 1.02rem;
  overflow: auto;
  height: 100%;
  width: 100%;
  li {
    width: 29.95rem;
    height: 4.27rem;
    background: #fff;
    border-radius: 0.85rem;
    margin-bottom: 0.68rem;
    .left-flex-box {
      padding: 0 1.37rem;
      height: 100%;
      justify-content: flex-start;
      font-size: 1.45rem;
      font-family: PingFang SC;
      font-weight: 400;
      color: #334681;
    }
    .level-item {
      img {
        margin-right: 1.37rem;
      }
    }
    .member-item {
      .level-item;
      .select {
        width: 1.71rem;
        height: 1.71rem;
        margin-right: 0;
        margin-left: auto;
        background-size: contain;
        background-image: url('~@/assets/image/mobile/selectnon.png');
      }
      .selected {
        background-image: url('~@/assets/image/mobile/selected.png');
      }
    }
    .avatar {
      width: 2.9rem;
      height: 2.9rem;
      border-radius: 50%;
    }
  }
}

.task-li {
  // width: 29.95rem;
  min-height: 6.14rem;
  background: #ffffff;
  border-radius: 0.34rem;
  margin-bottom: 0.68rem;
  padding-bottom: 0.85rem;
  overflow: hidden;

  .box1 {
    margin: 0.51rem 1.12rem 0.85rem;
    height: 2.05rem;
    justify-content: flex-start;

    // padding-top: 0.51rem;
    .status-ico {
      margin-right: 0.85rem;
      width: 5.97rem;
      height: 1.88rem;
      border-radius: 0.34rem;
      justify-content: flex-start;
      img {
        vertical-align: middle;
        margin: 0 0.51rem;
        width: 1.19rem;
        height: 1.19rem;
      }

      span {
        vertical-align: middle;
      }
    }

    .portrait {
      width: 2.05rem;
      height: 2.05rem;
      margin-right: 0;
      margin-left: auto;
    }
  }

  .title-show {
    vertical-align: middle;
    display: inline-block;
    padding: 0 1.12rem;
  }

  .etime {
    vertical-align: middle;
  }
}
</style>
