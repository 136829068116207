<template>
  <Common v-if="!isSelecting" :topStyle="topStyle" :mainStyle="mainStyle">
    <template v-slot:headers>
      <div @click="back" class="back-img" />
      <div class="title">任务</div>
      <div style="margin-right: 1.79rem; margin-left: auto" />
    </template>
    <template>
      <div v-if="summary.is_subtask === '1'" class="flex-w detail-box">
        <div>
          <span>父任务:</span>
          <span class="dad-title">{{ $store.state.kv['dadTitle'] }}</span>
        </div>
      </div>
      <div class="detail-box detail-title">
        <div class="html-text" v-html="summary.title"></div>
      </div>
      <div class="flex-w detail-box">
        <div class="item-title">当前状态</div>
        <div class="flex-w" style="height: 1.71rem">
          <div
            v-if="summary.status >= 0"
            :style="{ fontSize: '1.19rem', color: xIco[summary.status].color }"
            class="flex-w status-ico"
          >
            <img style="margin-right: 0.17rem; width: 1.71rem; height: 1.71rem" :src="xIco[summary.status].ico" alt />
            <span>{{ xIco[summary.status].str }}</span>
          </div>
          <i @click="handleNext('status')" class="el-icon-arrow-right"></i>
        </div>
      </div>
      <div class="flex-w detail-box">
        <div class="item-title">负责人</div>
        <div class="flex-w">
          <template v-if="summary.charge_name">
            <div class="head-draw">{{ lastName(summary.charge_name) }}</div>
            <span>{{ summary.charge_name }}</span>
          </template>
          <i class="el-icon-arrow-right" @click="handleNext('charge_name')"></i>
        </div>
      </div>
      <!-- <div class="flex-h detail-box height-auto">
        <div class="flex-w full-w between" style="height: 4.27rem">
          <div class="item-title">开始时间</div>
          <div class="flex-w">
            <span v-if="summary.stime">{{ summary.stime }}</span>
            <span v-else>选择时间</span>
            <i @click="handleNext('stime')" class="el-icon-arrow-right"></i>
          </div>
        </div>
        <div class="el-divider" style="margin: 0"></div>
        <div class="flex-w full-w between" style="height: 4.27rem">
          <div class="item-title">结束时间</div>
          <div class="flex-w">
            <span v-if="summary.etime">{{ summary.etime }}</span>
            <span v-else>选择时间</span>
            <i @click="handleNext('etime')" class="el-icon-arrow-right"></i>
          </div>
        </div>
      </div> -->
      <div class="flex-w detail-box">
        <div class="item-title">任务类型</div>
        <div @click="handleNext('type')">
          <span v-if="summary.type.length>0">{{ summary.type }}</span>
          <i  class="el-icon-arrow-right"></i>
        </div>
      </div>
      <div class="flex-w detail-box">
        <div class="item-title">任务紧急度</div>
        <div @click="handleNext('level')">
          <template v-if="summary.level != '' && summary.level >= 0">
            <img style="width: 1.17rem; height: 1.17rem" :src="tasklevel[summary.level].icon" />
            <span>{{ tasklevel[summary.level].value }}</span>
          </template>
          <i  class="el-icon-arrow-right"></i>
        </div>
      </div>
      <div class="flex-w detail-box">
        <div class="item-title">任务排序</div>
        <div @click="handleNext('sort')">
          <span >暂无字段</span>
          <i  class="el-icon-arrow-right"></i>
        </div>
      </div>
      <div class="flex-w detail-box" @click="handleNext('join_id')">
        <div class="item-title">参与人</div>
        <div class="full flex-w join-one-box" v-if="typeof summary.join_id == 'object'">
          <div class="head-draw join_head" v-for="item in summary.join_id">{{ lastName(item.name) }}</div>
        </div>
        <i  class="el-icon-arrow-right"></i>
      </div>
      <div class="flex-w detail-box content">
        <div class="item-title">描述</div>
        <div v-html="summary.content" class="text-html"></div>
      </div>
      <div class="flex-w detail-box" v-if="summary.is_subtask != 1">
        <div class="item-title">子任务</div>
        <div @click="handleNext('SubSum')">
          <span>{{ summary.SubSum }}</span>
          <i  class="el-icon-arrow-right"></i>
        </div>
      </div>
      <div class="flex-w detail-box">
        <div class="item-title">附件</div>
        <div>
          <span>{{ summary.UploadSum }}</span>
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <div class="detail-box comment">
        <div class="item-title">评论</div>
        <ul>
          <li class="flex-w" v-for="(item, index) in comment" :key="index">
            <div class="head-draw head">{{ lastName(item.name) }}</div>
            <div class="span">
              <div class="name">{{ item.name }}</div>
              <div class="ctime">{{ parseData(item.ctime) }}</div>
              <div class="comment-content">{{ item.content }}</div>
              <br />
            </div>
          </li>
        </ul>
      </div>
    </template>
  </Common>
  <formContainer v-else :para="formPara" @back="isSelecting = false" @handleChange="handleChange"></formContainer>
</template>

<script>
import {
  postTaskChargeMod,
  postTaskCommentLis,
  postTaskEtimeMod,
  postTaskInfoLis,
  postTaskStatusMod,
  postTaskStimeMod,
  postTaskJoinMod,
  postTaskLevelMod,
  postTaskTypeMod,
} from '@/service/help';
import { memberMixin, xIco, tasklevel } from '@/common/js';
import { dateFormat } from '@/utils/utils';
import formContainer from '@/components/mobile/formContainer/index.vue';
export default {
  props: ['para'],
  mixins: [memberMixin],
  data() {
    return {
      xIco,
      tasklevel,
      topStyle: 'detail-top',
      mainStyle: 'detail',
      summary: {},
      comment: [],
      isSelecting: false,
      formPara: null,
    };
  },
  mounted() {
    this.getData();
    // this.handleNext('SubSum');
  },
  computed: {
    taskId() {
      return this.para.id.toString();
      // return '603';
    },
  },
  methods: {
    handleChange({ data, type }) {
      console.log('拿到了', data, type);
      //抽公共map
      if (type == 'SubSum') {
        let id = data.id;
        this.$emit('back', id);
        this.$emit('goTo', 'taskMain', { data: { id } });
        return;
      }

      let commomMap = {
        status: {
          value: data.toString(),
          api: postTaskStatusMod,
        },
        charge_name: {
          value: data.name,
          api: postTaskChargeMod,
          restData: {
            charge_id: data.id,
          },
        },
        // stime: {
        //   value: dateFormat('YY-MM-DD hh:mm:ss', new Date(data.value)),
        //   api: postTaskStimeMod,
        //   check: () => {
        //     //开始时间不能大于结束时间
        //     let check = true;
        //     if (!this.summary.etime) {
        //       check = true;
        //       console.log(this.summary.etime, !this.summary.etime);
        //     } else if (new Date(this.summary.etime).getTime() < new Date(data.value)) {
        //       this.$toast.fail('开始时间不能大于结束时间');
        //       check = false;
        //     }
        //     return check;
        //   },
        // },
        // etime: {
        //   value: dateFormat('YY-MM-DD hh:mm:ss', new Date(data.value)),
        //   api: postTaskEtimeMod,
        //   check: () => {
        //     let check = true;
        //     if (!this.summary.stime) {
        //       check = true;
        //     } else if (new Date(this.summary.stime).getTime() > new Date(data.value)) {
        //       console.log('结束时间不能小于开始时间');
        //       this.$toast.fail('结束时间不能小于开始时间');
        //       check = false;
        //     }
        //     return check;
        //   },
        // },
        level: {
          value: data.toString(),
          api: postTaskLevelMod,
        },
        type: {
          value: data.toString(),
          api: postTaskTypeMod,
        },
        join_id: {
          value: data,
          api: postTaskJoinMod,
          restData: () => {
            return { joins_id: data.map(v => parseInt(v.id)) };
          },
        },
        sort:{ // 待填写

        }
      };
      let handler = commomMap[type];
      let value = handler.value;
      let subData = {
        id: this.taskId,
        [type]: value,
      };
      //走接口前，做参数检查正确或者不需要检查
      let check = handler.check ? handler.check() : true;
      if (check) {
        if (handler.restData) {
          //如果需要重新处理字段
          if (typeof handler.restData == 'function') {
            handler.restData = handler.restData();
          }
          handler.restData.id = this.taskId;
          subData = handler.restData;
          console.log(subData);
        }
        handler.api(subData).then(res => {
          handler.callback ? handler.callback(res) : null;
          commonCallback();
        });
      } else {
        this.isSelecting = false;
        //检查失败直接关
      }
      let commonCallback = () => {
        this.$toast.success('修改成功');
        this.$set(this.summary, type, value);
        this.isSelecting = false;
      };
    },
    handleNext(type) {
      console.log(type);
      let formTarget = {
        status: { type, title: '选择任务状态', id: 'statusLis', value: this.summary[type] },
        charge_name: { type, title: '负责人', id: 'memberLis', value: this.summary[type] },
        stime: { type, title: '选择开始时间', id: 'datePick', value: this.summary[type] },
        etime: { type, title: '选择结束始时间', id: 'datePick', value: this.summary[type] },
        level: { type, title: '选择任务紧急度', id: 'levelLis', value: this.summary[type] },
        type: { type, title: '选择任务类型', id: 'typeLis', value: this.summary[type] },
        sort: { type, title: '选择任务排序', id: 'sortInput', value: this.summary[type] },
        join_id: {
          type,
          title: '参与人',
          id: 'memberLis',
          value: this.summary[type],
          chill: true,
          handleOk: true,
          okText: '确定',
        },
        SubSum: { type, title: '子任务', id: 'taskLis', value: this.taskId, addtask: true },
      };
      let data = formTarget[type] || null;
      this.$set(this, 'formPara', data);
      this.isSelecting = true;
    },
    getData() {
      //获取总览详情数据
      this.getSummary();
      //获取评论
      this.getComment();
    },
    getSummary() {
      postTaskInfoLis({ id: this.taskId.toString() }).then(res => {
        let data = JSON.parse(JSON.stringify(res.data.data));
        if (data.join_id == '[]') data.join_id = [];
        this.$store.commit('set', { type: 'taskInfo', data });
        this.summary = data;
        this.summary.status = parseInt(this.summary.status);
        if (data.is_subtask === '0') {
          //需要保存父任务的标题
          this.$store.commit('setkv', { k: 'dadTitle', v: data.title });
          this.$store.commit('setkv', { k: 'dadId', v: data.id });
        }
      });
    },
    getComment() {
      postTaskCommentLis({ task_id: this.taskId.toString() }).then(res => {
        let data = res.data.data;
        this.comment = data;
      });
    },
    parseData(time) {
      return time.substr(5, 2) + '月' + time.substr(8, 2) + '日' + time.substr(11, 5);
    },
    back() {
      this.$emit('back');
      let componentId = process.env.VUE_APP_CONFIG === 'qcc' ? 'pccProgress' : 'taskMain'
      this.$emit('goTo', componentId, {
        handler: that => {
          console.log(that.traId,'====1====')
          that.traId.pop();
          if (that.traId.length > 0) {
            that.componentId = componentId;
            let id = that.traId.pop();
            console.log(that.traId,'====2====')
            that.$set(that, 'para', { id });
          }
        },
      });
    },
  },
  components: {
    formContainer,
  },
};
</script>

<style lang="less">
.detail {
  height: 100%;
  background-color: #f3f7f9;
  overflow-y: auto !important;
  .dad-title {
    font-size: 1.45rem;
    font-weight: 400;
    color: #13bfff;
  }

  .height-auto;
  .between {
    justify-content: space-between;
  }
  .height-auto {
    height: auto !important;
  }
  .detail-box {
    height: 4.69rem;
    background-color: #fff;
    border-radius: 0.85rem;
    padding: 0 1.37rem;
    margin-bottom: 0.68rem;
    .item-title {
      flex: 0 0 33%;
      overflow: hidden;
    }
    .text-html{
        img{
            max-width: 100%;
            height: auto;
            object-fit: contain;
        }
    }
    span {
      margin-left: 0.17rem;
      vertical-align: middle;
    }
    img {
      vertical-align: middle;
    }
    .main-font;
    .between;
    .main-font {
      font-size: 1.45rem;
      font-family: PingFang SC;
      font-weight: 400;
      color: #334681;
    }
    // .el-icon-arrow-right {
    //   margin-left: 1.45rem;
    //   color: #c2c7d9;
    //   font-size: 1.19rem;
    //   font-weight: 600;
    //   &::before {
    //     content: '' \e6e0'';
    //   }
    // }
    // background: #f3f7f9;
    //参与人
    .join-one-box {
      overflow-x: scroll;
      justify-content: flex-end !important;
      .join_head {
        width: 2.05rem;
        height: 2.05rem;
        line-height: 2.05rem;
        font-size: 0.68rem;
        margin-right: 0.85rem;
        flex: 0 0 auto;
      }
    }
  }
  .detail-title {
    font-size: 1.71rem;
    font-weight: 500;
    .content;
    .html-text {
      overflow: hidden;
    }
  }
  //描述
  .content {
    padding: 1.37rem;
    height: auto;
  }
  //评论
  .comment {
    padding: 1.37rem;
    height: auto;
    ul {
      margin-top: 0.85rem;
      li {
        justify-content: flex-start;
        align-items: flex-start;
        .head {
          flex-shrink: 0;
        }
        .span {
          margin-left: 0.68rem;
          .name {
            font-size: 1.19rem;
            font-family: PingFang SC;
            font-weight: 400;
            line-height: 1.71rem;
            color: #334681;
          }
          .ctime {
            font-size: 1.02rem;
            font-family: PingFang SC;
            font-weight: 400;
            line-height: 1.45rem;
            color: #8590b3;
            margin-top: 0.17rem;
            margin-bottom: 0.51rem;
          }
          .comment-content {
            // height: auto;
            font-size: 1.37rem;
            font-family: PingFang SC;
            font-weight: 400;
            color: #334681;
          }
        }
      }
    }
  }
}
.el-divider {
  margin: 0;
  height: 1px;
  width: 100%;
  background-color: #e4e9f2;
}
</style>
