<template>
    <div class="input-box">
        <div class="flex-h search" >
            <input class="search-input" v-model="inputValue" @keyup.enter="handleSearch" placeholder="请输入排序编码" />
        </div>
        <!-- 按钮 -->
        <div class="btn edit-btn" @click="handleSearch">保存</div>
    </div>

</template>

<script>
export default {
    props:['value','id'],
    data(){
        return{ 
            inputValue:''
        }
    },
    created(){
        this.inputValue = this.value
    },
    watch:{
        value(newVal){
            this.inputValue = newVal
        }
    },
    methods:{
        handleSearch(){
            if(!this.inputValue){
                this.$message({
                    type: 'error',
                    message: '排序编码不能为空！',
                });
                return
            }
            this.$emit('handleSave',{data:this.inputValue,type:this.id} )
        }
    }

}
</script>

<style lang="less" scoped>
.input-box{
    height: 100%;
    margin-top: 2.85rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .search {
        margin-bottom: 1.02rem;
        width: 32rem;
        height: 3.75rem;
        // background: #fff;
        border-radius: 1rem 1rem 0rem 0rem;
        .search-input {
            width: 29.27rem;
            height: 2.56rem;
            background-color: #fff;
            padding: 0.8rem;
            border-radius: 0.17rem;
            &::placeholder {
                text-align: center;
                text-indent: 2rem;
            }
            &:placeholder-shown {
                background-image: url('~@/assets/image/icon-progress/icon_search@2x.png');
                background-repeat: no-repeat;
                background-position: 12.54rem 0.68rem;
                background-size: 1.2rem 1.19rem;
            }
        }
    }

    .btn{
        padding: 7px 14px;
        display: inline-block;
        font-size: 14px;
        margin: 0 10px;
        cursor: pointer;
    }
    .edit-btn{
        border: 1px solid #409EFF;
        color: #409EFF;
    }
}
</style>
